.process-files-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.upload-content {
  padding: 0rem 1rem !important;
}

.operations-box {
  padding-top: 1rem;
}

.upload-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.call-upload-box {
  padding: 0rem 1rem;
}
.excel-upload-box {
  padding: 0rem 1rem;
}
