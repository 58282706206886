.main-content {
  margin-right: 80px;
  padding: 24px;
  min-height: 100vh;
}

.ant-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  transition: all 0.3s;
}

.ant-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.ant-table {
  background: white;
  border-radius: 8px;
}

.ant-statistic-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-statistic-content {
  font-size: 24px;
  font-weight: 600;
}
