.sidebar {
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  top: 0;
  height: 130vh;
  position: fixed;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-section {
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-card {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
