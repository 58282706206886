.agent-details {
  padding: 24px;
}

/* .ant-card {
  height: 100%;
} */

.ant-table-small {
  margin-top: 16px;
}
