.keyword-analysis-container {
  margin: 12px;
}

.keyword-card {
  margin-bottom: 16px;
}

.keyword-card:last-child {
  margin-bottom: 0;
}

.keyword-card .ant-card-head {
  background-color: #fafafa;
}

.ant-table-small .ant-table-cell {
  padding: 8px 16px;
}
.card-container {
  display: flex;
  align-items: stretch; /* Ensure both cards stretch to the same height */
  gap: 16px; /* Space between cards */
}

.card {
  flex: 1; /* Allow cards to grow equally */
  display: flex; /* Enable flexbox for card content */
  flex-direction: column; /* Stack content vertically */
  min-height: 300px; /* Set a minimum height to ensure uniformity */
}

.card > .ant-card-body {
  flex: 1; /* Allow the body to grow and fill the card */
  display: flex; /* Enable flexbox for body content */
  flex-direction: column; /* Stack content vertically */
}

.additional-data-container {
  margin: 12px;
}
