.agent-dashboard {
  padding: 24px;
}

.top-performers-card {
  height: 100%;
}

.top-performers-card .ant-card-body {
  padding: 12px 24px;
}

.improvement-card {
  height: 100%;
}

.agent-improvement-item {
  padding: 8px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.agent-improvement-item:last-child {
  margin-bottom: 0;
}

.agent-improvement-item > div {
  margin-bottom: 4px;
}

.agent-improvement-item > div:last-child {
  margin-bottom: 0;
}

.ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}

.ant-tabs-card .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.ant-tabs-card .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

/* Add padding control for the chart card */
.ant-card .recharts-wrapper {
  padding-bottom: 0; /* Remove bottom padding from chart */
}
